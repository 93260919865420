export type Config = {
  tehtavaSeriesId: string
  tehtavaApiUrl: string
  vuorovaikutusApiUrl: string
  jsonStorageApiUrl: string
  imsUrl: string
  showDevTools: boolean
  feedbackLink: string
  appId: string
  appKey: string
  useWebviewGuard: boolean
  revealLeaderboardButtonAfterUTC: string
  useVuorovaikutusApi: boolean
}

export type Env = 'prod' | 'test' | 'dev'

declare global {
  interface Window {
    getEnv: () => Env
  }
}

const config: Record<Env, Config> = {
  prod: {
    tehtavaSeriesId: '8c5247d8-5418-4606-bd92-5767721f0341',
    tehtavaApiUrl: 'https://tehtava.api.yle.fi/v1',
    vuorovaikutusApiUrl: 'https://vuorovaikutus.api.yle.fi',
    jsonStorageApiUrl: 'https://json-storage.api.yle.fi/v1',
    imsUrl: 'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    showDevTools: false,
    feedbackLink: 'https://forms.gle/AN3kz8iA2BFuSSev7',
    appId: 'tehtava_app_202012_prod',
    appKey: '86a5824c09676754de92ee809c9c677e',
    useWebviewGuard: true,
    revealLeaderboardButtonAfterUTC: '2023-02-05',
    useVuorovaikutusApi: false
  },
  test: {
    tehtavaSeriesId: 'eb50edca-1329-41ea-9b6a-8c23b37e0879',
    tehtavaApiUrl: 'https://tehtava.api-test.yle.fi/v1',
    vuorovaikutusApiUrl: 'https://vuorovaikutus.api-test.yle.fi',
    jsonStorageApiUrl: 'https://json-storage.api.yle.fi/v1',
    imsUrl: 'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    showDevTools: true,
    feedbackLink: 'https://forms.gle/AN3kz8iA2BFuSSev7',
    appId: 'tehtava_app_202012_dev_prod',
    appKey: '96462f9ea3d7170ec39c39ef959588ff',
    useWebviewGuard: false,
    revealLeaderboardButtonAfterUTC: '2023-02-05',
    useVuorovaikutusApi: false
  },
  dev: {
    tehtavaSeriesId: 'eb50edca-1329-41ea-9b6a-8c23b37e0879',
    tehtavaApiUrl: 'https://tehtava.api-test.yle.fi/v1',
    vuorovaikutusApiUrl: 'https://interaction.test.yle.fi',
    jsonStorageApiUrl: 'https://json-storage.api.yle.fi/v1',
    imsUrl: 'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    showDevTools: true,
    feedbackLink: 'https://forms.gle/AN3kz8iA2BFuSSev7',
    appId: 'tehtava_app_202012_dev_prod',
    appKey: '96462f9ea3d7170ec39c39ef959588ff',
    useWebviewGuard: false,
    revealLeaderboardButtonAfterUTC: '2023-02-05',
    useVuorovaikutusApi: false
  }
}

export default config[window.getEnv()]
