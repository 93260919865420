.resultspage {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  background: linear-gradient(to left, var(--background-linear-gradient-3), var(--background-linear-gradient-4))
}

.header {
  align-self: start;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.scoreList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.scoreItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  margin-top: -2px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  box-sizing: border-box;
}

.scoreHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  margin: 0 0;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  box-sizing: border-box;
}

.highlightedItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  margin-top: -2px;
  background: #f8de8e;
  color: black;
  position: sticky;
  bottom: 0;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  box-sizing: border-box;
}

.edgeHeader {
  font-size: 14px;
}

.centerHeader {
  font-size: 14px;
  font-weight: var(--font-weight--bold);
}

.rightCell {
  font-size: 18px;
}

.leftCell {
  font-size: 18px;
  /* color: var(--color-beige-3) */
}

.centerCell {
  margin: 0 8px;
  font-size: 18px;
  /* color: var(--color-beige-3); */
  text-align: center;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(to left, rgba(0, 0, 0, 0), 85%, rgba(0, 0, 0, 1)); */
  pointer-events: none;
}

.joinButton {
  width: min-content;
  white-space: nowrap;
}
